import React from 'react'

const RtoLVector = () => {
  return (
    <div className='container-fluid m-0 p-0'>
      <img src="Assets/img/VectorRtoL.svg" alt="" srcset="" className='w-100' />
    </div>
  )
}

export default RtoLVector
