import React from "react";
import { FaInstagram } from "react-icons/fa";
// import { PiGooglePlayLogoLight } from "react-icons/pi";
import "./Footer.css";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
const Footer = () => {
  const images = [
    "Assets/img/our works/c870x524.jpg",
    "Assets/img/our works/electrical-work-service-500x500.webp",
    "Assets/img/our works/How-Your-Home-Plumbing-System-Works.jpg",
    "Assets/img/our works/images (1).jpeg",
    "Assets/img/our works/images.jpeg",
    "Assets/img/our works/shutterstock_1551539639-1024x621.jpg"
  ];
  const menuItems = [
    {
      title: "Services",
      links: [
        "AC Maintenance",
        "Duct Cleaning",
        "Coil Cleaning",
        "Electrical",
        "Plumbing",
        "Painting",
        "Handyman",
        "Water Tank Cleaning",
        "Carpentry",
        "Flooring Installation",
        "Roof Repair",
        "And More"
      ],
    },
    {
      title: "Explore",
      links: ["Services", "Portfolio", "Testimonials", "Blog"],
    },
    {
      title: "About Us",
      links: ["Company Overview", "Our Team", "Mission & Vision", "Contact Us"],
    }
  ];


  return (
    <footer data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="1500" className="tech-footer">
      <div className="container">
        <div className="d-flex flex-wrap justify-content-between align-items-center pb-4">
          <img className="logo mx-auto mb-4 mb-md-0 mx-md-0" src="Assets/img/19 (1).jpg" alt="" srcset="" />
          <p className="text-1">
            Wadi Al Hateem is conveniently located in Dubai. Visit our office to meet our team, discuss your project in person, and explore how we can enhance your living spaces.
          </p>
        </div>
        <div
          className="row footer-middle-br"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-lg-6">
            <div className="row">
              {menuItems.map((menu, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <h2>{menu.title}</h2>
                  <ul>
                    {menu.links.map((link, subIndex) => (
                      <li key={subIndex}>
                        <a href="/">{link}</a>
                      </li>
                    ))}
                  </ul>
                  <hr className="border-white d-block d-lg-none d-md-none" />
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mt-md-4 mt-0 mt-lg-0 ">
            <div>
              <h2>Subscribe to our newsletter</h2>
              <div className="footer-newsletter-field-block">
                <form action="" method="">
                  <input placeholder="Enter your email..." type="text" name="" />
                  <button>Subscribe</button>
                </form>
              </div>
            </div>
            <div>
              <h2>OUR WORKS</h2>
              <div className="our-works">
                {images.map((image, index) => (
                  <img key={index} src={image} alt="" />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-0 mt-lg-4 mt-0 mt-md-4 align-items-center">
          <div className="col-12 col-lg-9 col-md-8 p-2 ">
            <p className="mb-4 mb-md-0 mb-lg-0">
              Copyright © 2024 Wadi Al Hateem Technical Services LLC. All rights reserved.
            </p>
          </div>
          <div className="col-12 col-md-4 col-lg-3 px-5 px-md-0 px-lg-0 col-lg-3 footer-bottom-section-col-2">
            <div className="footer-icon">
              <a href="https://www.instagram.com/wadialhateem.official/">
                <FaInstagram />
              </a>
              <a href="#">
                <BsLinkedin />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61555734766518&mibextid=ZbWKwL">
                <BsFacebook />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
