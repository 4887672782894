import React from "react";
import "./Services.css";

const serviceData = [
  {
    imgSrc: "Assets/img/Untitled design/21212.png",
    title: "Home Maintenance Services",
    points: [
      "General Home Repairs",
      "Appliance Maintenance",
      "Landscape Care",
      "Emergency Repairs"
    ]
  },
  {
    imgSrc: "Assets/img/Untitled design/7.png",
    title: "Electrical Services",
    points: [
      "Electrical Installations",
      "Wiring and Rewiring",
      "Fault Diagnosis and Repairs"
    ]
  },
  {
    imgSrc: "Assets/img/Untitled design/6.png",

    title: "Plumbing Expertise",
    points: [
      "Leak Detection and Repairs",
      "Drain Cleaning",
      "Fixture Installations"
    ]
  },
  {
    imgSrc: "Assets/img/Untitled design/8.png",
    title: "Air Conditioning Services",
    points: [
      "Air Conditioning Repair",
      "AC Installation",
      "Routine Maintenance"
    ]
  },
  {
    imgSrc: "Assets/img/Untitled design/5.png",
    title: "Renovation and Remodeling",
    points: [
      "Home Renovation",
      "Office Renovation",
      "Modernization Projects"
    ]
  },
  {
    imgSrc: "Assets/img/Untitled design/2.png",

    title: "Painting Solutions",
    points: [
      "Interior Painting",
      "Exterior Painting",
      "Custom Artwork and Murals"
    ]
  },
  {
    imgSrc: "Assets/img/Untitled design/Untitled design.png",

    title: "False Ceiling",
    points: [
      "Enhance aesthetic appeal",
      "Skilled craftsmen for design and installation",
      "Complements interior decor",
      "Provides practical benefits"
    ]
  },
  {
    imgSrc: "Assets/img/Untitled design/31.png",
    title: "Tiling Works",
    points: [
      "Transform floors and walls",
      "Expert tiling services",
      "Precision installation"
    ]
  },
  {
    imgSrc: "Assets/img/Untitled design/9.png",
    title: "Building Cleaning",
    points: [
      "Thorough cleaning for every nook and cranny",
      "Regular maintenance and deep cleaning",
      "Ensures a pristine building"
    ]
  },
  {
    imgSrc: "Assets/img/Untitled design/2121.png",
    title: "Carpentry Works",
    points: [
      "Quality craftsmanship",
      "Custom furniture and woodwork",
      "Adds warmth and functionality",
      "Skilled carpenters for various projects"
    ]
  },
  {
    imgSrc: "Assets/img/Untitled design/32432423.png",
    title: "Partition Installation",
    points: [
      "Create functional spaces",
      "Tailored partitions for needs",
      "Optimizes space usage",
      "Stylish and cohesive design"
    ]
  }
];

const TechServices = () => {
  return (
    <div className="services">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="cards-content">
              <button>Discover Our Services</button>
              <h2>Explore Our Services</h2>
              <p>Experience unparalleled technical services in Dubai tailored to your needs. Our expert team ensures excellence in every aspect, exceeding expectations with proficiency and quality.</p>
            </div>
          </div>
          {serviceData.map((service, index) => (
            <div
              key={index}
              className={`col-12 col-md-6 col-lg-4 ${index === 4 ? "position-relative" : ""
                }`}
            >
              <div className="services-cards">
                <div>
                  <img src={service.imgSrc} alt={service.title} />
                </div>
                {index === 4 && (
                  <img
                    className="position-absolute cent-img translate-middle"
                    src="Assets/img/cyber-security 1.png"
                    alt=""
                    srcSet=""
                  />
                )}
                <div>
                  <h3 className="h-white">{service.title}</h3>
                  <ul>
                    {service.points.map((point, idx) => (
                      <li key={idx}>
                        <p className="h-white">{point}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div >
  );
};

export default TechServices;
