import React, { useState } from 'react';
import { BiSupport } from 'react-icons/bi';
import { FaInstagram, FaFacebook, FaGoogle, FaLinkedin, FaWhatsapp } from 'react-icons/fa';

const ParentSocialBar = () => {
    const [showParentBar, setShowParentBar] = useState(true);
    const [showChildBar, setShowChildBar] = useState(false);

    const toggleBars = () => {
        setShowParentBar(prevState => !prevState);
        setShowChildBar(prevState => !prevState);
    };

    const hideChildBar = () => {
        setShowChildBar(false);
    };

    return (
        <>
            {showParentBar && (
                <div className="parent-icon-bar">
                    <a href="#" className="parent-icon" onClick={toggleBars}><BiSupport /></a>
                </div>
            )}
            {showChildBar && (
                <div className="child-icon-bar">
                    <a href="https://www.instagram.com/wadialhateem.official/" onClick={hideChildBar}><FaInstagram /></a>
                    <a href="https://www.facebook.com/profile.php?id=61555734766518&mibextid=ZbWKwL" onClick={hideChildBar}><FaFacebook /></a>
                    <a href="mailto:wadialhateem@gmail.com" onClick={hideChildBar}><FaGoogle /></a>
                    <a href="https://www.linkedin.com" onClick={hideChildBar}><FaLinkedin /></a>
                    <a href="https://wa.me/+971559687692" onClick={hideChildBar}><FaWhatsapp /></a>
                </div>
            )}
        </>
    );
};

export default ParentSocialBar;
