import React from "react";
import "./Ourprogress.css";

const workData = [
  {
    imgSrc: "Assets/img/supports/1.png",
    title: "24/7 Customer Support",
    alt: "24/7 Customer Support - Online Helpdesk",
  },
  {
    imgSrc: "Assets/img/supports/2.png",
    title: "Service Satisfaction",
    alt: "Service Satisfaction - Happy Customer",
  },
  {
    imgSrc: "Assets/img/supports/3.png",
    title: "Excellent Customer Service",
    alt: "Excellent Customer Service - Support Team",
  },
  {
    imgSrc: "Assets/img/supports/4.png",
    title: "Online payment option",
    alt: "Online payment option - Secure Transactions",

  }
];

const WorkWithUs = () => {
  return (
    <section className="tech-about-work-with-us mb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h5>Our Services</h5>
            <h1>
              Why <span>work</span> with us ?
            </h1>
            <p>A quick preview of the work quality we are offering</p>
          </div>
        </div>
        {/*  */}
        <div className="row mt-work">
          {workData.map((work, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-12">
              <div className="card-progres" style={{ marginTop: "3rem" }}>
                <div className="about-work-card-img">
                  <img
                    className="position-absolute top-0 start-50 translate-middle"
                    src={work.imgSrc}
                    alt={work.alt}
                    id="color-img"
                  />
                </div>
                <div className="card-body">
                  <h5 className="card-title">{work.title}</h5>
                  <p className="card-text">{work.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkWithUs;
