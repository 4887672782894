import React from "react";

const LtoR = () => {
  return (
    <div className="container-fluid m-0 p-0">
      <img src="Assets/img/VectorLtoR.svg" alt="" srcset="" className="w-100" />
    </div>
  );
};

export default LtoR;
