import React from "react";
import "./Landingbannar.css";
import "../../../Pages/Responsive.css";
import { Link } from "react-router-dom";

const TechAboutBanner = () => {
  return (
    <section className="landing-bg-img">
      <div className="tech-about-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="land-content mt-5 mt-lg-0 mt-md-2">
                <h1>
                  <span>WAH
                  </span>  Technical <span>Services</span>
                </h1>
                <div className="d-block d-lg-none">
                  <img
                    src="Assets/img/baa29b5d-f1fc-4775-bd25-6222c0277743.png"
                    alt="About Banner"
                    className="w-auto mt-lg-0 mt-md-0"
                  />
                </div>
                <p>
                  WAH Technical Services provides a comprehensive range of home maintenance solutions in Dubai.</p>
                <p>
                  Our expertise extends to carpentry, encompassing door locks, hinges, handles repair, and gypsum partition making. We also specialize in masonry, marble, and tiling work, ensuring precision and quality in every project.

                </p>
                <Link to='/contact'>
                  <button>Contact Us</button>
                </Link>
                {/* <div className="d-flex align-items-center gap-15">
                <div className="icons-banner">
                  <a href="/">
                    <FaInstagram />
                  </a>
                </div>
                <div className="icons-banner">
                  <a href="/">
                    <img src="Assets/img/Vector.png" alt="" />
                  </a>
                </div>
                <div className="icons-banner">
                  <a href="/">
                    <BiLogoPlayStore />
                  </a>
                </div>
              </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 d-none d-lg-block">
              <img
                src="Assets/img/baa29b5d-f1fc-4775-bd25-6222c0277743.png"
                alt="About Banner"
                className="banner-image mt-lg-0 mt-md-0 w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechAboutBanner;
