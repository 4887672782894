import React from "react";
import Contact from "../Home/Contact/Contact";
import "./abouts.css";
const About = () => {
  return (
    <>
      <div className="about-bannar pricing-bannar">
        <div className="container">
          <div className="row align-items-center" id="Media-resp">
            <div
              className="col-12 col-lg-6 pt-5 pt-lg-0 pt-md-0 mt-4"
              id="margin"
            >
              <div className="pricing-bannar-content  text-center text-lg-start text-md-start float-start float-md-end float-lg-end">
                <h6 style={{ fontWeight: "600" }}>About Us</h6>
                <h1 className="heading">
                  <span>WAH</span> TECHNICAL
                  <br /> SERVICES
                </h1>
                <p style={{ color: "#000000" }}>
                  Wah Technical Services is a leading moving company based in
                  Dubai, United Arab Emirates. We have been providing
                  professional moving services since 2009. Wah Technical
                  Services offers nationwide packing and moving services,
                  catering to various areas including the Marina, JLT, and
                  throughout Dubai.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-6 ">
              <div className="">
                <img
                  src="Assets/img/e23ea5ce-4a1e-4dfd-b5da-ff82963a3898.png"
                  alt=""
                  srcset=""
                  className=" w-100 h-auto rounded-5"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid my-5">
        <img
          className="w-100 h-auto"
          src="Assets/img/White and Blue Professional Modern Technology Pitch Deck Presentation (1).png"
          alt=""
        />
        <Contact />
      </div>
      {/* <div style={{ background: "#364163" }} className=" p-3">

      </div> */}
    </>
  );
};

export default About;
