import React, { useState } from "react";
import "./Navbar.css";
import { FaArrowRight } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { Navbar, Nav, Button } from "react-bootstrap";
import { FiMenu } from "react-icons/fi";
import { Link, NavLink } from "react-router-dom";
import { SiWhatsapp } from "react-icons/si";

// import SelectFlag from "../../UI-Style/SelectFlag";
const HomeImage = "Assets/img/Untitled design (4).png";
const DollarImage = "Assets/img/doller.svg";

const Header = () => {
  const [activeLink, setActiveLink] = useState("Home");
  const handleLinkClick = (link) => {
    setActiveLink(link);
    closeNav();
  };

  const [isNavOpen, setNavOpen] = useState(false);
  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  const getPageIcon = () => {
    if (activeLink === "Pricing") {
      return <img src={DollarImage} alt="Dollar" />;
    } else {
      return <img src={HomeImage} alt="HomeImage" />;
    }
  };

  const navItems = [
    { text: "Home", path: "/", id: "Home" },
    { text: "Services", path: "/Services", id: "Services" },
    { text: "Portfolio", path: "/Portfolio", id: "Pricing" },
    { text: "Testimonial", path: "/Testimonials", id: "Testimonial" },
    { text: "About", path: "/About", id: "About" },
    { text: "Contact", path: "/Contact", id: "Contact" }
  ];

  return (
    <header className="tech-header">
      <div className="tech-nav-top-bar">
        <div className="container">
          <div className="d-flex justify-content-between px-0 px-md-4">
            <a href="https://wa.me/971528809935" target="_blank" rel="noopener noreferrer">
              <SiWhatsapp /> (+97) 1528809935
            </a>
            <a href="#">
              <IoLocationOutline />
              D08 Building International City Dubai
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <Navbar className="bg-body-tertiary" expand="lg" variant="dark">
          <Navbar.Brand href="/">{getPageIcon()}</Navbar.Brand>
          <FiMenu
            aria-controls="navbarScroll"
            onClick={toggleNav}
            className="custom-toggler d-block d-lg-none"
            style={{ marginRight: "1rem", fontSize: "2rem" }}
          />
          <Navbar.Collapse
            id="navbarScroll"
            className={isNavOpen ? "show" : "justify-content-between"}
          >
            <Nav
              className="mr-auto my-2 my-lg-0 navbar-nav-scroll"
              style={{ "--bs-scroll-height": "200px" }}
            >
              {navItems.map((navItem) => (
                <Nav.Item key={navItem.id}>
                  <Link
                    to={navItem.path}
                    className={`nav-link ${activeLink === navItem.id ? "active" : ""}`}
                    onClick={() => handleLinkClick(navItem.id)}
                  >
                    {navItem.text}
                  </Link>
                </Nav.Item>
              ))}
            </Nav>
            <NavLink className='text-decoration-none' to="/Contact">
              <Button
                variant="outline-success"
                type="submit"
                className="d-flex align-items-center justify-content-between ps-4 custom-button "
              >
                <h6 className="pt-2">Get An Appointment</h6>
                <div className="circle-box">
                  <FaArrowRight />
                </div>
              </Button>
            </NavLink>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
