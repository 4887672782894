import React from "react";
import Bannar from "./Bannar/Bannar";
// import PricingTable from "./PricingTable/Pricingtable";

const Pricing = () => {
  return (
    <div>
      <Bannar />
      {/* <PricingTable /> */}
    </div>
  );
};

export default Pricing;
