import React from "react";
import Slider from "react-slick";
import LtoR from "../../../UI-Style/LtoRVector";
import "./Testimonials.css";

const testimonialData = [
    {
        imgSrc: "Assets/img/Rating/1.png",
        text: "I was truly impressed by the quality of service provided by Wadi AlHateem Technical Services. They exceeded my expectations and I would highly recommend them to anyone.",
        clientImgSrc: "Assets/img/images.jpeg",
        clientName: "Talat",
        clientTitle: "Sharjah",
    },
    {
        imgSrc: "Assets/img/Rating/2.png",
        text: "Wadi AlHateem Technical Services provided exceptional support throughout our project. Their team was professional, knowledgeable, and delivered results on time.",
        clientImgSrc: "Assets/img/images.jpeg",
        clientName: "Andrew Jones",
        clientTitle: "AL Manama",
    },
    {
        imgSrc: "Assets/img/Rating/2.png",
        text: "Wadi AlHateem Technical Services went above and beyond to ensure our project's success. Their professionalism and expertise made the entire process smooth and efficient.",
        clientImgSrc: "Assets/img/images.jpeg",
        clientName: "Beth Wilson",
        clientTitle: "Ajman",
    },
    // ---
    {
        imgSrc: "Assets/img/Rating/1.png",
        text: "Wadi ALHateem Technical services LLC are the most efficient services I have experienced so far. Hired the team on several occasions and they never disappointed me.",
        clientImgSrc: "Assets/img/images.jpeg",
        clientName: "Deepak",
        clientTitle: "Sharjah",
    },
    {
        imgSrc: "Assets/img/Rating/1.png",
        text: "Recommended for all-technical services in Dubai. Had a wonderful experience with extremely fine services.",
        clientImgSrc: "Assets/img/images.jpeg",
        clientName: "Hussain",
        clientTitle: "Dubai",
    },
];

const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2, // Adjust the number of slides shown as needed
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };

    return (
        <>
            <LtoR />
            <div className="testimonial">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="testimonial-title">
                                <button>Testimonials</button>
                                <h2 className="mx-auto mx-lg-0 mx-md-auto">
                                    Hear what our clients say
                                </h2>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 text-center">
                            <Slider {...settings}>
                                {testimonialData.map((testimonial, index) => (
                                    <div
                                        className='testimonial-cards shadow '
                                        key={index}
                                    >
                                        <div className="testimonial-logo">
                                            <img
                                                src={testimonial.imgSrc}
                                                alt={`Testimonial ${index + 1}`}
                                            />
                                        </div>
                                        <div>
                                            <p>{testimonial.text}</p>
                                        </div>
                                        <div>
                                            <div className="testimonial-client-info d-flex align-items-center gap-3">
                                                {testimonial.clientImgSrc && (
                                                    <img
                                                        src={testimonial.clientImgSrc}
                                                        alt={`Client ${index + 1}`}
                                                    />
                                                )}
                                                <div>
                                                    <h4>{testimonial.clientName}</h4>
                                                    <p>{testimonial.clientTitle}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Testimonials;
