import React from "react";
import "./Seoimportant.css";

const seoContent = [
  {
    description:
      "At Wadi Al Hateem, we stand out as your premier choice for quality technical services in Dubai. Our commitment to excellence and customer satisfaction sets us apart. Here's why you should choose us.",
  },
  {
    title: "Expert Technicians",
    description:
      "Our team comprises highly skilled and trained technicians.",
  },
  {
    title: "Quality Workmanship",
    description:
      "We are committed to delivering services that meet the highest standards of quality.",
  },
  {
    title: "Customer Satisfaction",
    description:
      "Your satisfaction is our priority. We ensure transparency, reliability, and open communication.",
  },
];

const Seoimportant = () => {
  return (
    <div>
      <section className="tech-about-seo-importance">
        <div className="row">
          <div className="col-lg-6 col-12 p-0">
            <div className="left-content">
              <h1 className="text-center text-lg-start text-md-start">
                Why Choose <span>WAH Technical Services</span>
              </h1>
              {seoContent.map((content, index) => (
                <div key={index}>
                  <h5>{content.title}</h5>
                  <p>{content.description}</p>
                </div>
              ))}
            </div>
          </div>
          <div
            className="col-lg-6 col-12"
            style={{ backgroundColor: "rgba(149, 167, 251, 0.1)" }}
          >
            <div className="right">
              <div className="right-content">
                <p>
                  Choose Wadi Al Hateem for a seamless blend of professionalism and creativity, transforming your space into a haven of comfort and style.
                </p>
                <form>
                  {["Name", "Address", "Phone Number"].map(
                    (placeholder, index) => (
                      <div className="mb-3" key={index}>
                        <input
                          type="text"
                          className="form-control input-inputs"
                          placeholder={placeholder}
                          id="input"
                        />
                      </div>
                    )
                  )}
                  <button type="submit" className="btn btn-primary submit-btn">
                    Get Quote Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Seoimportant;
