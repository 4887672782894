import React from "react";
import "./Components/Pages/Responsive.css";
import "./App.css";
import Navbar from "./Components/Common/Navbar/Navbar";
import Home from "./Components/Pages/Home/Home";
import Pricing from "./Components/Pages/Pricing/Pricing";
import LandingPage from "./Components/Pages/LandingPage/Landing";
import Footer from "./Components/Common/Footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from "./Components/Pages/Home/Contact/Contact";
import Testimonials from "./Components/Pages/Home/Testimonials/Testimonials";
import About from "./Components/Pages/About/About";
import ParentSocialBar from "./Components/UI-Style/SocialBar";
function App() {
  return (
    <>
      <Router>
        <ParentSocialBar />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Services" element={<LandingPage />} />
          <Route path="/Portfolio" element={<Pricing />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About />} />
          <Route path="/Testimonials" element={<Testimonials />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
