import React from "react";
import "./Seo.css";

const AboutSEO = () => {
  return (
    <div className="">
      <section className="tech-about-seo">
        <div className="row">
          <div className="col-lg-7 col-12 ">
            <div className="about-seo-bg">
              <img src="Assets/img/fd318dnu_gallery_images_01.png" alt="SEO" className="" />
            </div>
          </div>
          <div className="col-lg-5 col-12 ">
            <div className="Seo-responsive">
              <div className="container">
                <h1>
                  Provide AC<span> Services</span>
                </h1>
                <p>
                  AC installation involves setting up a new air conditioning system in your home or commercial space. It includes mounting the indoor unit (evaporator coil) and the outdoor unit (condenser coil), connecting refrigerant lines, electrical wiring, and ensuring proper insulation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tech-about-seo">
        <div className="row">
          <div className="col-lg-6 col-12 order-2 order-lg-1">
            <div className="Seo-responsive">
              <div className="container">
                <h1>
                  Provide Painting<span> Services</span>
                </h1>
                <p>
                  If wall painting your home isn’t your thing, WAH Technical Services' professional team of experienced painters is here to help. For smaller projects such as individual rooms and walls, we can provide you with a quick quote, and our team of experts will visit you to assess larger projects before providing a detailed estimate. As a leading provider of professional painting services, you can count on WAH Technical Services to meet all your painting requirements in Dubai.  </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 order-1 order-lg-2 ">
            <div className="about-seo-bg-2">
              <img src="Assets/img/jhsdksjdksjdh.png" alt="SEO" className="w-100" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutSEO;
