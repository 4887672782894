import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import "./Contact.css"; // Import your CSS file
import { FaMapMarkerAlt, FaEnvelope } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import RtoLVector from "../../../UI-Style/RtoLVector";

const contactInfo = [
  {
    title: "Location",
    content: "D08 Building International City Dubai",
    icon: <FaMapMarkerAlt />
  },
  {
    title: "Email",
    content: "wadialhateem@gmail.com",
    icon: <FaEnvelope />
  },
  {
    title: "Call",
    content: "(+97) 1528809935",
    icon: <BsFillTelephoneFill />
  }
];

const Contact = () => {
  const form = useRef();
  const [error, setError] = useState("");

  const YOUR_SERVICE_ID = 'service_f3n4jse';
  const YOUR_TEMPLATE_ID = 'template_qya39w9';
  const YOUR_PUBLIC_KEY = 'ku5RUa6Okci3Ft_r1';

  const sendEmail = (e) => {
    e.preventDefault();
    const formElements = form.current.elements;
    const from_name = formElements.from_name.value.trim();
    const from_email = formElements.from_email.value.trim();
    const from_phone = formElements.from_phone.value.trim();
    const subject = formElements.subject.value.trim();
    const message = formElements.message.value.trim();

    if (!from_name || !from_email || !from_phone || !subject || !message) {
      setError("All fields are required.");
      return;
    }

    setError("");

    emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, form.current, YOUR_PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send message, please try again later.');
      });
  };

  return (
    <>
      <RtoLVector />
      <section className="contact">
        <div className="container">
          <div className="conatct-sub-section">
            <div className="contact-form-heading">
              <h1>Contact info</h1>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
                <div className="">
                  {contactInfo.map((info, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center gap-3 mt-2 down"
                    >
                      <div className="tech-contact-about-icon">{info.icon}</div>
                      <div className="tech-contact-about-text">
                        <h5>{info.title}</h5>
                        <p>{info.content}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 col-lg-8 col-md-8">
                <form ref={form} onSubmit={sendEmail}>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      <input type="text" name="from_name" placeholder="Your Name" />
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <input type="email" name="from_email" placeholder="Your Email" />
                    </div>
                    <div className="col-12">
                      <input type="text" name="from_phone" placeholder="Phone Number" />
                    </div>
                    <div className="col-12">
                      <input type="text" name="subject" placeholder="Subject" />
                    </div>
                    <div className="col-12">
                      <textarea name="message" placeholder="Write your message..." rows="5"></textarea>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "60%",
                        marginTop: "1rem"
                      }}
                    >
                      <div
                        style={{
                          width: "30%"
                        }}
                      >
                        <button style={{ color: 'white' }} className="btn btn-dark">Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
