import React from "react";
import Banner from "./Bannar/Bannar";
import Services from "./Services/Services";
import Testimonials from "./Testimonials/Testimonials";
import SlideTestimonial from "./Testimonials/SlideTestimonial";
import Contact from "./Contact/Contact";
import Ourprogress from "./../LandingPage/OurProgress/Ourprogress"
import "../Responsive.css";
// import ServicesSlide from "./Services/ServicesSlide";

const Home = () => {
  return (
    <div className="overflow-x-hidden">
      <Banner />
      {/* <RtoLRound /> */}
      {/* <div className="d-none d-lg-block"> */}
        <Services />
      {/* </div> */}
      {/* <div className="d-block d-lg-none"> */}
        {/* <ServicesSlide /> */}
      {/* </div> */}
      <Ourprogress />
      <div className="d-none d-lg-block">
        <Testimonials />
      </div>
      <div className="d-block d-lg-none">
        <SlideTestimonial />
      </div>
      <Contact />
    </div>
  );
};

export default Home;
