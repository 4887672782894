import React from 'react';
import './Seoservices.css';
import Slider from 'react-slick';
const SEOServices = () => {
    const seoServiceData = [
        {
            imgSrc: 'Assets/img/Untitled design/1.png',
            title: 'General Home Repairs',
            points: [
                'Covers a wide range of maintenance needs around the house',
                'Addresses common issues like fixing broken fixtures',
                'Repairing damaged surfaces',
                'And more',
            ]
        },
        {
            imgSrc: 'Assets/img/Untitled design/2.png',
            title: 'Appliance Maintenance',
            points: [
                'Ensures the proper functioning and longevity of household appliances',
                'Includes regular servicing and repairs as needed',
                'And more',
            ]
        },
        {
            imgSrc: 'Assets/img/Untitled design/3.png',
            title: 'Landscape Care',
            points: [
                'Maintenance and upkeep of outdoor spaces such as gardens, lawns, and yards',
                'Services may include lawn mowing, trimming, weeding, and planting',
                'And more',
            ]
        },
        {
            imgSrc: 'Assets/img/Untitled design/5.png',
            title: 'Emergency Repairs',
            points: [
                'Immediate assistance for urgent repair needs, such as burst pipes, electrical faults, etc.',
                'Available 24/7 to address critical issues to prevent further damage',
                'And more',
            ]
        },
        {
            imgSrc: 'Assets/img/Untitled design/4.png',
            title: 'Electrical Services',
            points: [
                'Comprehensive electrical solutions for homes and businesses',
                'Covers installations, wiring, repairs, and troubleshooting of electrical systems',
                'And more',
            ]
        },
        {
            imgSrc: 'Assets/img/Untitled design/6.png',
            title: 'Plumbing Expertise',
            points: [
                'Specialized services for plumbing-related issues',
                'Includes leak detection, drain cleaning, and fixture installations',
                'And more',
            ]
        },
        {
            imgSrc: 'Assets/img/Untitled design/8.png',
            title: 'Air Conditioning Services',
            points: [
                'Maintenance, repair, and installation of air conditioning systems',
                'Ensures optimal performance and efficiency of AC units',
                'And more',
            ]
        },
        {
            imgSrc: 'Assets/img/Untitled design/7.png',
            title: 'Renovation and Remodeling',
            points: [
                'Transformative services to upgrade and enhance living or office spaces',
                'Includes full-scale renovation projects and modernization efforts',
                'And more',
            ]
        },
        {
            imgSrc: 'Assets/img/Untitled design/21212.png',
            title: 'Painting Solutions',
            points: [
                'Interior and exterior painting services',
                'Offers custom artwork and murals for personalized aesthetics',
                'And more',
            ]
        },
        {
            imgSrc: 'Assets/img/Untitled design/10.png',
            title: 'False Ceiling',
            points: [
                'Enhances interior design with decorative ceiling solutions',
                'Improves aesthetics and provides practical benefits like insulation and soundproofing',
                'And more',
            ]
        },
        {
            imgSrc: 'Assets/img/Untitled design/2121.png',
            title: 'Carpentry Works',
            points: [
                'Custom furniture creation and installation',
                'Expert woodwork for cabinets, shelves, and more',
                'Precision work to achieve desired aesthetic and functionality',
                'And more',
            ]

        },
        {
            imgSrc: 'Assets/img/Untitled design/9.png',
            title: 'Building Cleaning',
            points: [
                'Thorough cleaning services for buildings',
                'Regular maintenance and deep cleaning to ensure a clean and hygienic environment',
                'And more',
            ]
        },
    ];


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <section className="tech-about-seo-services">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h5>Services</h5>
                        <h1><span>Our</span> Services</h1>
                    </div>
                    <div className="col-12">
                        <Slider {...settings}>
                            {seoServiceData.map((service, index) => (
                                <div key={index} className="col-lg-4 col-md-6">
                                    <div className="card mx-auto mb-5">
                                        <div className="about-seo-service-card-img">
                                            <img src={service.imgSrc} alt={`SEO Service ${index + 1} - ${service.title} | Top SEO Solutions`} />
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">{service.title}</h5>
                                            <ul className="card-text ps-4 pt-3">
                                                {service.points.map((point, idx) => (
                                                    <li key={idx}>{point}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SEOServices;