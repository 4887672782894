import React from "react";
import "./Bannar.css";
const Bannar = () => {
  return (
    <>
      <div className="pricing-bannar">
        <div className="container">
          <div className="row align-items-center" id="Media-resp">
            <div className="col-12 col-lg-6">
              <div className="pricing-bannar-img">
                <img
                  src="Assets/img/1000x600.jpg"
                  alt=""
                  srcset=""
                  className="w-100"
                />
              </div>
            </div>
            <div className="col-12 col-lg-6  pt-5 pt-lg-0 pt-md-0 mt-4" id="margin">
              <div className="pricing-bannar-content text-center text-lg-start text-md-start float-start float-md-end float-lg-end">
                <h6 style={{ fontWeight: "600" }}>
                  GREAT TECHNOLOGY

                </h6>
                <h1 className="heading">
                  <span>WAH</span> TECHNICAL
                  <br /> SERVICES

                </h1>
                <p style={{ color: "#000000" }}>
                  As a proficient company operating in Dubai, WAH Technical Services always strives to meet the demands of its clients with skill and capability.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bannar;
