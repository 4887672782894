import React from "react";
import Button from "react-bootstrap/Button";
import "./Bannar.css";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";


const TechBanner = () => {
  return (
    <section className="tech-banner">
      <Container>
        <Row>
          <Col lg={8} md={100}>
            <div className="tech-banner-content pt-5 pt-lg-0">
              <div>
                <div>
                  <h1>
                    Wadi
                    <span> AlHateem Technical Services</span>
                  </h1>
                </div>
                <h4>Your Trusted <span>
                  Partner for Quality Technical Services in Dubai!
                </span>
                </h4>
                <div>
                  <p>
                    At Wadi Al Hateem, we take pride in delivering top-notch technical services to our valued clients in Dubai. From air conditioning repair and renovation to painting, electrical work, plumbing, and expert wallpaper fixing, and etc Renovation Services for Offices and Homes: Transforming Spaces, Creating Experiences we are your one-stop solution for all your technical service needs.
                  </p>
                  <div className="text-center text-lg-start text-md-start mt-lg-1">
                    <Link className="text" to="/Contact">
                      <Button variant="primary">
                        GET A QOUTE
                      </Button>
                    </Link>
                  </div>
                </div>
                
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TechBanner;
